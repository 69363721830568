<template>
  <PageOverlay :overlayOpen="detailsPageOpen" :loading="loading" @close-overlay="closeDetailsPage" >
    <template v-if="product">
      <p v-if="product.providerName" class="provider-name">{{ product.productName }}</p>
      <div class="product-offering">
        <div class="product-offering-image">
          <img :src="product.logoUri" alt="product logo" />
          {{ product.providerName }}
        </div>
        <div class="product-offering-information">
          <div class="information-box centered-md">
            <span class="product-offering-amount">{{ formData.totalFundingRequired | numberSeparator | currency }}</span>
            <span class="product-offering-provider">Funding required</span>
          </div>
          <div class="information-box centered-md">
            <span class="product-offering-general-bold">{{ formatDate(product.closingDate) }}</span>
            <span class="product-offering-general">Closing date</span>
          </div>
          <div class="information-box centered-md">
            <span class="product-offering-general-bold">{{ product.isSpecificBusinessSector ? 'Yes' : 'No' }}</span>
            <span class="product-offering-general">Sector Preference</span>
          </div>
          <div class="information-box centered-md">
            <span class="product-offering-general-bold">{{ product.isSpecificRegion ? 'Yes' : 'No' }}</span>
            <span class="product-offering-general">Geographic Preferences</span>
          </div>
        </div>
      </div>
      <p class="disclaimer" v-if="product.canSubmitForSwoopReview">Swoop does not earn any commission from this matching service. If you progress and choose to use a grant writer for your application, Swoop may receive a commission related to the cost of this service.</p>
      <p class="disclaimer" v-else>Swoop Grantfinder sponsored by Lloyds is a search engine service. We do not process, approve or distribute grants. The service levels to process your grant application are the responsibility of your local grant provider.</p>
      <div class="cta-control">
        <button v-if="grantReviewButton === 'Get Swoop Help'" @click="toggleSignUpForm" class="btn btn-primary">Get Swoop Help</button>
        <a v-if="grantReviewButton === 'Direct link'" :href="product.externalReviewUrl" target="_blank" @click="trackGoToProviderEvent" class="btn btn-transparent">Go direct to {{ product.providerName }}</a>
      </div>
      <Form :subtitle="'Once you have signed up below, a member from our grants team will be in touch.'" :class="{ 'open': displaySignUpForm }" :form="form" :backButtonEnabled="false" @submit="signUpFormSubmit" />
      <h1>Product information</h1>
      <div v-html="product.detailedDescription" class="injected-product-information"></div>
    </template>
  </PageOverlay>
</template>

<script>
import { formatDate } from '@/utils/utils'
import { amplitudeEvents } from '@/utils/constants'
import { notificationRoutine } from '@/store/modules/notification/routines'
import { getGrant, grantFinderGrantRegistration } from '@product/api/products/'
import store from '@/store'
import Form from '@product/features/products/components/Form'
import PageOverlay from '@/components/PageOverlay'
import checkGrantFinderRedirects from '../mixins/checkGrantFinderRedirects'

export default {
  name: 'Grant',
  mixins: [checkGrantFinderRedirects],
  components: { Form, PageOverlay },
  data() {
    return {
      displaySignUpForm: false,
      form: [
        {
          label: 'First name',
          name: 'firstName',
          type: 'input',
          rules: 'required',
          value: ''
        },
        {
          label: 'Last name',
          name: 'lastName',
          type: 'input',
          rules: 'required',
          value: ''
        },
        {
          label: 'Phone number',
          name: 'phoneNumber',
          type: 'input',
          rules: 'required',
          value: ''
        },
        {
          label: 'Email Address',
          name: 'emailAddress',
          type: 'input',
          rules: 'required|email',
          value: ''
        }
      ],
      formData: this.getFormData(),
      product: null,
      loading: true,
      detailsPageOpen: false
    }
  },
  computed: {
    grantReviewButton() {
      if (this.product.canSubmitForSwoopReview || !this.product.externalReviewUrl) {
        return 'Get Swoop Help'
      }
      if (this.product && this.product.externalReviewUrl) {
        return 'Direct link'
      }
      return null
    }
  },
  mounted() {
    this.redirectToForm()
    this.fetchProduct()
    this.$nextTick(() => {
      setTimeout(() => {
        this.detailsPageOpen = true
      }, 10)
    })
  },
  methods: {
    getFormData() {
      const formData = localStorage.getItem('formData')

      return formData ? JSON.parse(formData) : null
    },
    redirectToForm() {
      if (!this.formData) {
        this.$router.push({ name: 'lloyds-form' })
      }
    },
    fetchProduct() {
      getGrant(this.$route.params.id).then(({ data }) => {
        this.product = data
        this.loading = false
      })
    },
    formatDate,
    closeDetailsPage() {
      this.detailsPageOpen = false
      this.$router.go(-1)
    },
    trackSignUpEvent() {
      this.$ma.trackEvent({
        eventType: amplitudeEvents.lloydsSMEGrants.GRANT_SIGN_UP,
        eventProperties: {
          productName: this.product.productName,
          providerName: this.product.providerName
        }
      })
    },
    trackGoToProviderEvent() {
      this.$ma.trackEvent({
        eventType: amplitudeEvents.lloydsSMEGrants.GRANT_GO_TO_PROVIDER,
        eventProperties: {
          productName: this.product.productName,
          providerName: this.product.providerName
        }
      })
    },
    toggleSignUpForm() {
      this.displaySignUpForm = !this.displaySignUpForm
    },
    signUpFormSubmit(data) {
      grantFinderGrantRegistration({
        ...data,
        ...this.formData,
        companyName: this.formData.company.companyName,
        registeredAddress: {
          city: this.formData.registeredAddress.city,
          country: this.formData.registeredAddress.country,
          postcode: this.formData.registeredAddress.postcode
        },
        tradingAddress: {
          city: this.formData.tradingAddress.city,
          country: this.formData.tradingAddress.country,
          postcode: this.formData.tradingAddress.postcode
        },
        productName: this.product.productName,
        providerName: this.product.providerName
      }).then(() => {
        store.dispatch(notificationRoutine.TRIGGER, {
          type: 'success',
          title: 'Grant',
          text: 'Success'
        })
        this.displaySignUpForm = false
        this.trackSignUpEvent()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';

.provider-name {
  font-weight: 500;
  margin-bottom: 4px;
}

h1 {
  margin: 12px 0;
}

.injected-product-information {
  p {
    margin: 0;
  }
}

p.disclaimer {
  font-size: 12px;
  padding: 0;
  margin-top: -8px;
  margin-bottom: 8px;
}

/deep/.form-wrapper {
  height: 0;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  margin-top: 30px;

  p {
    text-align: center;
    margin-bottom: 0;
  }

  &.open {
    height: fit-content;
  }

  .field-wrapper .field label {
    width: 400px;
  }
}

.cta-control {
  display: flex;
  justify-content: center;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }

  .btn {
    min-width: 256px;

    @media only screen and (max-width: 620px) {
      margin: 8px 0;
    }

    &:nth-child(2) {
      margin-left: 16px;

      @media only screen and (max-width: 620px) {
        margin-left: 0;
      }
    }
  }
}

.product-offering {
  display: flex;
  flex-direction: row;
  min-height: 118px;
  transition: all 0.3s;
  border: 1px solid var(--color-secondary-50);
  background-color: $color-white;
  border-radius: 8px;
  margin-bottom: 16px;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    width: 100%;
    margin: auto;
  }

  .product-offering-image {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid var(--color-secondary-50);
    flex-direction: column;
    font-size: 12px;
    font-weight: 500;
    width: 280px;

    @media only screen and (max-width: 600px) {
      padding: 24px 12px 0 12px;
      width: 100%;
      border-right: none;
    }

    img {
      width: 140px;

      @media only screen and (max-width: 600px) {
        width: 200px;
      }
    }
  }

  .product-offering-information {
    width: 100%;
    padding: 20px 40px;
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: $breakpoint-sm-max) {
      padding: 8px;
      flex-direction: column;
      justify-content: center;
      align-items: start;
    }

    .information-box {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 25%;

      &.centered-md {
        justify-content: center;
        @media only screen and (max-width: $breakpoint-sm-max) {
          justify-content: space-between;
        }
      }

      @media only screen and (max-width: $breakpoint-sm-max) {
        width: 100%;
        flex-direction: row-reverse;
        justify-content: space-between;
        margin: 4px 0;
      }

      span {
        margin: 2px 0;
      }

      span.product-offering-provider {
        font-size: 14px;
        font-weight: 500;
      }
      span.product-offering-amount {
        font-size: 24px;
        font-weight: 500;
        color:--color-primary-500;
      }
      span.product-offering-category {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        border-radius: 16px;
        color: $color-royal-purple;
        border: 1px solid $color-royal-purple;
        width: 120px;

        &.innovation-grant {
          color: var(--color-primary-400);
          border-color: var(--color-primary-300);
        }
      }
      span.product-offering-general-bold {
        font-size: 20px;
        font-weight: 500;
        color: var(--color-primary-500);
      }
      span.product-offering-general {
        font-size: 14px;
        font-weight: 500;
      }
    }

    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      align-self: center;
      height: 32px;
      width: 25%;

      @media only screen and (max-width: $breakpoint-xs-max) {
        width: 100%;
        margin-top: 8px;
      }
    }
  }
}
</style>
